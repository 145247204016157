import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
// import PricingPage from "./pages/PricingPage";
// import LoginPage from "./pages/LoginPage";
import VoicePage from "./pages/VoicePage"; // Подключаем вашу новую страницу

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/*<Route path="/pricing" element={<PricingPage />} />*/}
          {/*<Route path="/login" element={<LoginPage />} />*/}
          <Route path="/voice" element={<VoicePage />} /> {/* Новая страница */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
