import React from "react";
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="homepage-container">
      <header className="homepage-header">
        <div className="logo">Learn English Online</div>
        <nav className="homepage-nav">
          {/*<a href="/pricing">Pricing</a>*/}
          <a href="/voice">Voice</a>
          {/*<a href="/login" className="login-button">Login Page</a>*/}
          {/*<a href="/signin" className="signin-button">Sign In</a>*/}
        </nav>
      </header>

      <main className="homepage-main">
        <h1>Learn English Online</h1>
        <h2>Raise your english skills with <span className="highlight">AI</span></h2>
        <ul className="features-list">
          <li>🔉 <strong>Voice Speaker:</strong> Speak with your personal teacher in any time.</li>
          <li>📝 <strong>History Saving:</strong> Save your dialog for the future.</li>
        </ul>

        <div className="email-signup">
          <input type="email" placeholder="Type your email" />
          <button>Start Learning English</button>
        </div>
        <p className="login-note">If you already have an account, we will log you in</p>
      </main>
    </div>
  );
};

export default HomePage;
