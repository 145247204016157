// VoicePage.js
import React, { useState, useRef } from "react";
import axios from "axios";
import "./VoicePage.css";

const VoicePage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.start();

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
        audioChunksRef.current = [];

        const formData = new FormData();
        formData.append("file", audioBlob);
        const uploadUrl = process.env.REACT_APP_API_URL || "http://api:8000";

        axios
          .post(`${uploadUrl}/upload`, formData)
          .then((response) => {
            const { audioFileName } = response.data;
            const fullUrl = `${uploadUrl}/static/${audioFileName}`;

            axios
              .get(fullUrl, { responseType: "blob" })
              .then((response) => {
                const url = URL.createObjectURL(new Blob([response.data], { type: "audio/wav" }));
                setAudioURL(url);
              })
              .catch((error) => console.error("Error getting the file: ", error));
          })
          .catch((error) => console.error("Error uploading the file: ", error));
      };
    });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
  };

  const handleButtonClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    setIsRecording(!isRecording);
  };

  return (
    <div className="voicepage-container">
      <header className="voicepage-header">
        <div className="logo">Learn English Online</div>
        <nav className="voicepage-nav">
          <a href="/">Home</a>
        </nav>
      </header>

      <main className="voicepage-main">
        <h1>Voice Recorder</h1>
        <div className={isRecording ? "recorder recorder-active" : "recorder"}>
          <button
            className={`record-button ${isRecording ? "recording" : ""}`}
            onClick={handleButtonClick}
          >
            {isRecording ? "Stop" : "Start"}
          </button>
        </div>
        {audioURL && (
          <div>
            <h2>Answer Record</h2>
            <audio controls src={audioURL}></audio>
          </div>
        )}
      </main>
    </div>
  );
};

export default VoicePage;
